import * as _express2 from "express";

var _express = "default" in _express2 ? _express2.default : _express2;

import * as _mongoose2 from "mongoose";

var _mongoose = "default" in _mongoose2 ? _mongoose2.default : _mongoose2;

import _sneaksRoutes from "./routes/sneaks.routes.js";
import * as _dotenv2 from "dotenv";

var _dotenv = "default" in _dotenv2 ? _dotenv2.default : _dotenv2;

import _sneaksControllers from "./controllers/sneaks.controllers.js";
import _process from "process";

var _global = typeof globalThis !== "undefined" ? globalThis : typeof self !== "undefined" ? self : global;

var exports = {};
var process = _process;
const express = _express;
const app = express();
const mongoose = _mongoose;

_sneaksRoutes(app);

_dotenv.config();

const SneaksAPI = _sneaksControllers;
var port = process.env.PORT || 4000;
mongoose.Promise = _global.Promise;
/*app.listen(port, function () {
  console.log(`Sneaks app listening on port `, port);
 });*/

exports = app;
exports = SneaksAPI;
export default exports;