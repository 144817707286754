import * as _mongoose2 from "mongoose";

var _mongoose = "default" in _mongoose2 ? _mongoose2.default : _mongoose2;

var exports = {};
const mongoose = _mongoose;
const Schema = mongoose.Schema;
var SneakerSchema = new Schema({
  shoeName: String,
  brand: String,
  silhoutte: String,
  styleID: String,
  retailPrice: Number,
  releaseDate: String,
  description: String,
  imageLinks: [String],
  thumbnail: String,
  urlKey: String,
  make: String,
  goatProductId: Number,
  colorway: String,
  resellLinks: {
    stockX: String,
    stadiumGoods: String,
    goat: String,
    flightClub: String
  },
  size: Number,
  lowestResellPrice: {
    stockX: Number,
    stadiumGoods: Number,
    goat: Number,
    flightClub: Number
  },
  resellPrices: {
    stockX: {},
    goat: {},
    stadiumGoods: {},
    flightClub: {}
  }
});
var Sneaker = mongoose.model("Sneaker", SneakerSchema);
exports = Sneaker;
export default exports;